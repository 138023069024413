import React, { Component } from "react"
import styled from "styled-components"
import { Section, QuickInfoTitle } from "../../utils"

export default class QuickInfo extends Component {
  render() {
    return (
      <QuickInfoWrapper>
        <Section>
          <div className="row">
            <div className="col-sm-4" align="center">
              <QuickInfoTitle title="Hours" />
              <p className="text">

              <strong>Monday: 11am to 8pm</strong>
              <br />
              <strong>Tuesday: CLOSED</strong>
              <br />
              <strong>Wed-Fri: 11am to 8pm</strong>
              <br />
              <strong>Sat-Sun: BRUNCH 10am to 2pm, DINNER 2pm to 8pm</strong>
              </p>
            </div>
            <div className="col-sm-4" align="center">
              <QuickInfoTitle title="Location" />
              <p className="text">
                <strong>42 Depot St.</strong>
                <br />
                <strong>Dillsboro, NC 28725</strong>
                <br />
                <strong>
                  <a
                    href="https://goo.gl/maps/P4UQz3q7xCw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (View Map)
                  </a>
                </strong>
              </p>
            </div>
            <div className="col-sm-4" align="center">
              <QuickInfoTitle title="Contact" />
              <p className="text">
                <a href="tel:828-631-4114" rel="noopener noreferrer">828-631-4114</a>
                <br />
                <strong>
                  <a href="https://foragerscanteen.revelup.com/weborder/" rel="noopener noreferrer" target="_blank">Order Online</a>
                </strong>
              </p>
            </div>
          </div>
        </Section>
      </QuickInfoWrapper>
    )
  }
}

const QuickInfoWrapper = styled.div`
  background: firebrick;
  .text {
    font-size: 1.2rem;
    color: #fff;
  }
  a {
    color: tan;
  }
`
