import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HomeHeader, BannerCenter, BannerButton } from "../utils"
import img from "../images/bcg/fresh-herbs.jpg"
import beef from "../images/bcg/beef.jpg"
import sprouts from "../images/bcg/from-scratch.jpg"
import QuickInfo from "../components/HomePageComponents/QuickInfo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`Restaurant and bar in Sylva, Dillsboro, and greater Jackson County NC`]} />
    <HomeHeader img={img}>
      <BannerCenter
        title="Farm Freshness"
        subtitle="Indoor/Outdoor Dining & Takeout"
      >
        <BannerButton>
          <a href="https://foragerscanteen.revelup.com/weborder/" rel="noopener noreferrer" target="_blank">ORDER ONLINE NOW</a>
        </BannerButton>
        <br />
        <BannerButton>
          <a href="/menu/">View the Menus</a>
        </BannerButton>
      </BannerCenter>
    </HomeHeader>
    <QuickInfo></QuickInfo>
    <HomeHeader img={beef}>
      <BannerCenter
        title="Got Beef?"
        subtitle="Humanely raised. Vegetarian fed. Antibiotic free. And insanely delicious."
      >
        <hr />
        <p>
          We carefully source 100% of our beef from local purveyors, and serve
          only the finest possible cuts. Whether it's lunch or dinner, rare or
          well-done, every cut of beef that passes through our kitchen is
          free-range, grass fed, antibiotic- and hormone-free, and meets or
          exceeds GAP Step 4 standards for ethical treatment. In other words,
          it's beef the way nature intended.
        </p>
        <BannerButton><Link to="/menu/">Learn About Our Philosophy</Link></BannerButton>
      </BannerCenter>
    </HomeHeader>
    <HomeHeader img={sprouts}>
      <BannerCenter
        title="From Scratch"
        subtitle="Sometimes it's just better to do things the old-fashioned way."
      >
        <hr />
        <p>
          Especially when those things are growing, picking, and cooking
          vegetables. And when you live, cook, and eat in as stunningly
          beautiful and prolific country as we do, getting veggies from anywhere
          other than the ground seems... Well... Ungrateful
        </p>
      </BannerCenter>
    </HomeHeader>
  </Layout>
)

export default IndexPage
